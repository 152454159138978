import APPPLICATION from "@/constants/application";
import EVENTS from "@/constants/events";
import CONSTANT_MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import store from "@/store/index";
import hash from "object-hash";
import _ from "lodash";

let global = new GlobalServices();

export default {
  data() {
    return {
      APP: {},
      MODULE: {},
      EVENT_ACTION: {},

      timer: {}, //Keeps track of any setInterval function call, so we can clear it in the unmount

      //Keep track of if the list is currently being filtered
      isFiltering: false,
      filterEvent: "FilterEvent",

      filter: {
        actionId: 0,
      },
    };
  },
  methods: {
    onFilter() {},
  },
  async mounted() {},

  async created() {
    this.$root.$on(this.filterEvent, this.onFilter);
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);

    //Unscribe from save button event...
    this.$root.$off(this.filterEvent);
  },
};
